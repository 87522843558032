.ez-modal{
    display: none;
    z-index: 10000001;
    position: fixed;
    top: 50%;
    left: 50%;
    /*this compensate the centering using the negative margins*/
    -webkit-transform-origin: 0px 0px;
    -moz-transform-origin: 0px 0px;
    transform-origin: 0px 0px;

    /*
        to center manually
        the modal must have a width
        and you should define a margin-left: -(width/2)
    */
}

.ez-modal-backdrop{
    display: none;
    z-index: 10000000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url(//i.dailymail.co.uk/i/furniture/fff/preload-inline-inverse.gif);
    background-repeat: no-repeat;
    background-position: center;
}

.ez-modal-container-on .ez-modal-backdrop{
    background-image: none;
}

/*
 backdrop effects
*/
.ez-modal-backdrop{
    background-color: #000;
    opacity: 0;

    -webkit-transition: opacity 400ms ease-in-out;
    -moz-transition: opacity 400ms ease-in-out;
    transition: opacity 400ms ease-in-out;
}

.ez-modal-backdrop.ez-modal-backdrop-on{
    opacity: 0.8;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
}


/*
sample modals effects
*/

/* initial state */
.ez-modal{
   -moz-transition: opacity 400ms, -moz-transform 400ms ease-in-out;
   -webkit-transition: opacity 400ms, -webkit-transform 400ms ease-in-out;
   transition: opacity 400ms, transform 400ms ease-in-out;
   opacity: 0;


    -webkit-transform: translateY(-80px);
    -o-transform:translateY(-80px);
    transform: translateY(-80px);
}

/* open state */
.ez-modal.ez-modal-on{
    opacity: 1;

    -webkit-transform: translateY(0px);
    -o-transform:translateY(0px);
    transform: translateY(0px);
}

/* closing state */
.ez-modal.ez-modal-off{

    -webkit-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
}

/*error*/
.ez-modal-error{
    height: 200px;
    width: 400px;
    padding: 20px;
    background-color: #fff;
    margin-left: -200px;
    margin-top: -100px;
    font-size: 20px;
    text-align: center;
    border: #F3F3F3 solid 40px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
