.email-lightbox {
    position: relative;
    background-color:#F3F3F3;
    width: 750px;
    top: 60px;
    margin-left: -380px;
    height: 587px;
    margin-top: -355px;
}
.sent {
    height: 273px;
}
.email-lightbox .email-lbx  {
    padding: 20px;
    *padding: 7px;
    overflow: hidden;
    _height: auto;
    *text-align: left;
}
.email-lightbox .email-lbx .close {
    position: absolute;
    right: -14px;
    top: -14px;
    display: block;
    height: 29px;
    width: 29px;
    overflow: hidden;
    background: url("//i.dailymail.co.uk/i/furniture/reg-new/close_light.png") no-repeat 0 0 transparent;
}
.email-lightbox .email-lbx .close:hover {
    background-position: 0 -28px;
}

.email-lightbox div.email-form {
    background-color: #fff;
    padding: 15px;
    height: 515px;
}
.sent div.email-form {
    height: 190px;
}
.email-lightbox .email-form h1  {
    font-size: 2.5em;
    margin-bottom: 10px;
}

div.email-lightbox.sent form#sendArticleEmailForm,
div.email-form div.sendArticleMessage {
    display: none;
}
div.email-lightbox.sent div.sendArticleMessage {
    display: block;
}

.email-fld {
    position: relative;
    margin: 20px 0;
    min-height: 37px;
}

.email-lbl {
    margin-right: 10px;
    font-weight: bold;
    padding: 8px 0;
    text-align: right;
    width: 175px;
}

.fail .email-lbl {
    color: #E01C44;
}

.email-fld .text-input {
    width: 340px;
    padding: 7px 9px 8px 9px;
    background-color:#F9F9F9;
    border: 1px solid #EDEDED;
}
.email-fld .text-input:focus, .email-fld .text-area:focus {
    outline: #33bbdb solid 2px;
}
.email-fld .text-area {
    resize: none;
}
.email-fld #message {
    height: 90px;
    width: 340px;
}

.email-fld span.msg {
    display: none;
    position: absolute;
    right: 0;
    top: -5px;
    width: 110px;
    border: solid 1px #cceef6;
    background-color: #e5f6fa;
    padding: 4px;
}
.fail span.msg {
    display: block;
}
.fail span.msg:before {
    content: " ";
    display: block;
    background: url("//i.dailymail.co.uk/i/furniture/reg-new/reg_hint_arrow.png") no-repeat 0 0 transparent;
    width: 10px;
    height: 17px;
    position: absolute;
    left: -10px;
}

/*recaptha*/
#recaptcha {
    width: 360px;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

#recaptcha_challenge_image{
    height: auto;
    max-width: 100%;
}

.recaptcha-wrap-image{
    border-top: 1px solid #e3e3e3;
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
}

#recaptcha_image{
    padding: 5px 2px;
    font-size: 13px;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    overflow: hidden;

    width: auto !important;
    height: 60px !important;
    max-width: 100% !important;
}

.recaptcha-buttons > a{
    display: inline-block;
    height: 33px;
    width: 33px;
    background-color: #004DB3;
    border-radius: 2px;
    margin:3px;
}

.recaptcha-buttons > a .icon{
    margin: 3px;
}

.recaptcha-buttons .icon{
    display: block;
    height: 26px;
    width: 26px;
    background-image: url(//i.dailymail.co.uk/i/furniture/reg-new/recaptcha-small.png);
    background-repeat: no-repeat;
    text-indent: 100%;
    overflow: hidden;
    font-size: 0;
    color: transparent;
}
.recaptcha-buttons .icon.icon-reload{
    background-position: 0 -26px;

}
.recaptcha-buttons .icon.icon-audio{
    background-position: 0 0px;

}
.recaptcha-buttons .icon.icon-image{
    background-position: 0 -52px;

}
.recaptcha-buttons .icon.icon-help{
    background-position: 0 -78px;
}

.recaptcha-buttons > a:hover{
    background-color: #2668be !important;
}

.recaptcha-buttons{
    float: right;
    margin: 4px 0px;
}

.recaptcha-credits{
    font-size: 11px;
    margin: 8px 5px;
}

.recaptcha_is_showing_audio .recaptcha_only_if_image,
.recaptcha_isnot_showing_audio .recaptcha_only_if_audio,
.recaptcha_had_incorrect_sol .recaptcha_only_if_no_incorrect_sol,
.recaptcha_nothad_incorrect_sol .recaptcha_only_if_incorrect_sol {
    display:none !important
}

/*recaptcha end*/

.btn-holder {
    padding: 10px 39%;
}
.email-btn {
    position: relative;
    border: 0;
    vertical-align: middle;
    display: inline-block;
    width: 120px;
    padding: 12px 0;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    font-size: 1.4em;
    margin: 0 15px;
    box-sizing: border-box;
    border-radius: 2px;
}
.email-form .email-loading {
    margin: 20px;
    visibility: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(229,229,229,0.5) url(//i.dailymail.co.uk/i/furniture/fff/preload-inline.gif) no-repeat 50% 50%;
}
.email-form .email-loading.show {
    visibility: visible;
}

.sendArticleMessage .shareArticles {
    width: 100%;
}
.sendArticleMessage .sc-icns, .sendArticleMessage .sc-icns li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.sendArticleMessage .sc-icns li {
    float: left;
}
#socialLinks .bigLinks li.shareAgain {
    font-weight:bold;
    font-size: 1.3em;
    line-height: 35px;
    width: 150px;
}
.sendArticleMessage #shareCountBottom .shares_text {
    font-size: 1.3em;
}
