#lightbox-shim {
    z-index:89;
    background:transparent;
    position:absolute;
    top:0;
    left:0;
    display:none;
    border:0;
}
#overlay,
#lightbox-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.6;
    filter: alpha(opacity=60);
    width: 100%;
    height: 100%;
    z-index: 100000;
}
#lightbox-container {
    position: absolute;
    display: block;
    z-index: 100002;
    left: -10000px;
    top: 0;
}
#lightbox {
    position: relative;
    border-collapse: collapse;
}
#lightbox td {
    background-color: #fff;
}
#lightbox td.tl, #lightbox td.tr, #lightbox td.bl, #lightbox td.br {
    background-color: transparent;
    position: relative;
    width: 8px;
    height: 8px;
}
#lightbox #lightbox-fader {
    background-color: #fff;
    z-index: 100003;
    position: absolute;
    top: 0;
    left: 0;
}
#lightbox #lightbox-content {
    position: relative;
    text-align: left;
}
#lightbox #lightbox-default-container {
    overflow:hidden;
    _height: auto;
}
